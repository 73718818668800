.main-verificattion {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin-top: 3rem;
 
}

.photo-verification {
  height: 230px;
  /* width: 40%; */
  margin-top: 3rem;
  display: flex;
  /* border: 1px solid black; */
  justify-content: center;
}

.container {
  display: flex !important;
  justify-content: center !important;
  width: 60%;
  flex-direction: column;
  /* border: 1px solid black; */
  align-items: center;
}

.photo-verification img {
  height: 70%;
  width: auto;
  border: 1px solid rgb(240, 235, 235);
  padding: 4px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.adhar-verification {
  height: 230px;
  margin-top: 2.6rem;

  display: flex;
  justify-content: center;
}

.adhar-verification img {
  height: 70%;
  width: auto;
  padding: 2px;
  border: 1px solid rgb(240, 235, 235);
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.btn-container p {
  margin-left: 5px;
  font-size: 22px;
  width: max-content;
}

.btn-container-Adhar p {
  font-size: 18px;
}

.btn-container p {
  font-size: 18px;
  text-decoration: none;
}

.btn-container {
  width: fit-content;

  display: flex;
  justify-content: center;
  border-radius: 25px;
  margin-top: 2rem;
  border: 1px solid #5e72e4;
  height: 50px !important;
  padding: 5px 10px 5px 10px;
  color: black;
}

.btn-container:hover {
  text-decoration: underline;
}

.btn-container-Adhar {
  display: flex;
  justify-content: center;

  border-radius: 25px;
  margin-top: 2rem;
  border: 1px solid #5e72e4;
  padding: 5px 10px 5px 10px;
  color: black;
}

.btn-container-Adhar:hover {
  text-decoration: underline;
}

.submit-btn {
  position: relative;
  bottom: 0.5rem;
  width: 100%;
  justify-content: center;
  display: flex;
}

.submit-btn button {
  height: 40px;
  width: 200px;

  border-radius: 10px;
  color: #fff;
  background-color: #5e72e4;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  /* transform: all 0.7s; */
}

.submit-btn button:hover {
  background-color: #fff;
  color: #5e72e4;
  cursor: pointer;
  border: 2px solid;
}

.house-shape img {
  height: 30px;
}

.house-shape img {
  height: 30px;
}

.bottom_Images {
  /* border: 1px solid black; */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  overflow-y: scroll;
  height: 200px;
  /* padding-left: 1rem; */
  flex-wrap: wrap;
  width: 34%;
  justify-content: center;
  /* margin-left: 10rem; */
  gap: 10px;
  margin-top: 1rem;
  margin-left: 1rem;
}

.bottom_Images::-webkit-scrollbar {
  display: none;
}

.bottom_aadharImages_Images::-webkit-scrollbar {
  display: none;
}

.bottom_aadharImages_Images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 40%;
  /* height: fit-content; */
  gap: 10px;
  position: relative;
  margin-top: 1rem;
  overflow-y: scroll;
  height: 200px;
  /* border: 1px solid black; */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  padding-left: 1rem;
  margin-right: 4.3rem;
}

.inner_bottomAadhar_images img {
  width: 130px;
  height: 100px;
  border: 1px solid rgb(213, 213, 213);
  margin: 1px;
  border: 1px solid #5e72e4;
  padding: 5px;
}

.inner_bottom_images img {
  width: 100px;
  height: 100px;
  border: 1px solid #5e72e4;
  margin: 2px;
  padding: 5px;
}

.photo-verification :hover {
  cursor: pointer;
}

.adhar-verification :hover {
  cursor: pointer;
}

.btn_delete_images {
  border-radius: 50%;

  border: 1px solid rgb(136, 133, 133);
  display: flex;
  position: relative;
  left: 100px;
  /* margin-left: -0.5rem; */
}
.btn_delete_aadhaar_images {
  border-radius: 50%;

  border: 1px solid rgb(136, 133, 133);
  display: flex;
  position: relative;
  left: 100px;
  top: 10px;
}
@media (max-width: 1024px) {
  .main-verificattion {
    flex-direction: column;
    height: auto;
    /* justify-content: space-evenly; */
  }
  .add_more_data_main {
    margin-top: 4rem;
  }
  .adhar-verification img {
    width: 190px;
    height: 150px;
  }

  .photo-verification img {
    width: 130px;
    height: 130px;
  }

  .bottom_Images {
    height: 120px;
    /* border: 1px solid black; */
    margin-top: 5rem;
    width: 107px;
  }

  .bottom_Images img {
    width: 50px;
    height: 50px;
  }

  .bottom_aadharImages_Images {
    height: 135px;
    /* border: 1px solid black; */
    width: 220px;
    margin-top: 4.9rem;
    margin-right: 2.7rem;
  }

  .bottom_aadharImages_Images img {
    width: 70px;
    height: 60px;
  }
  .webCamCamera {
    width: 200px !important;
  }
  .webcameUserImage {
    width: 150px !important;
  }
  /* video{height: 200px;} */
}

/* //AddMoreData Css */
.add_more_data_main {
  /* width: 80%; */
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: end;
  position: relative;
  flex-direction: column;
  gap: 100px;
}
.add_more_data_inner {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.close_form {
  display: flex;
  justify-content: end;
  /* border: 1px solid black; */
}
.close_form_icon {
  border: 1px solid rgb(117, 114, 114);
  font-size: 30px;
  border-radius: 5px;
  cursor: pointer;
}
.add_more_data_inner_form {
  display: flex;
  /* border: 1px solid black; */
  position: relative;
  top: 47px;
  flex-direction: column;
  align-items: center;

  /* gap: 45px; */
}
.next_previous_button {
  /* border: 1px solid black; */
  padding: 0.2rem;
  border-radius: 5px;
  width: 30px;
}
.next_previous_main_div {
  /* border: 1px solid black; */
  display: flex;
  gap: 15px;
}
.btn_image_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
