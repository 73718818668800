.corporateNav {
  height: max-content;
  width: 100%;
  background-color: #e2e5f8;
  display: flex;
  align-items: end;
  padding: 15px;
}
.dropdown-toggle-main {

  width: 100%;
  display: flex;
  justify-content:space-evenly;align-items: center;
}
.inner-dropdown-toggle{border: 1px solid black;}