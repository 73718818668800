.houses-view-inner-div-house-no {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    margin: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.6rem;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;margin-top: -1rem;
  }
  .house-modal-view-content-div{display: flex;align-items: center;justify-content: space-between;}