.add-announcement-main-div {
  height: auto;
  background-color: white;
  border-radius: 10px;

  padding: 15px;
}
.add-announcement-form-div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
.edit-input {
  width: 100%;
  padding: 5px 5px 5px 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.add-announcement-form-div label {
  font-family: "Montserrat", sans-serif !important;
  text-align: left;
  width: 70%;
}
.view-announcement-edit-input-div {
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  /* border: 1px solid black; */
}
.view-announcement-edit-input-div input,
.view-announcement-edit-input-div textarea,
.view-announcement-edit-input-div select {
  width: 70% !important;
}
.view-announcement-edit-input-div textarea {
  height: 200px;
}
.view-announcement-errors-msg {
  color:black !important;
  font-size: 13px;
  text-align: left;
  width: 70%;
}
