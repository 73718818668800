.profile_setting_main_div {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_inner_div {
  width: 80%;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: white;
}
.profile_inner_div_admin {
  width: 98%;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: white;
}
.profile-photo {
  /* width: 23%; */
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.back_profile {
  height: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  font-size: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back_profile_icon {
  font-size: 30px;
}

.profile-image-div {
  width: 140px;
  height: 140px;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 50%;
}

.user-content {
  /* width: 100%; */
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 42px;
  gap: 20px;
  font-size: 20px;
  justify-content: flex-start;
}

.user-content_admin {
  width: 85%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 42px;
  gap: 20px;
  font-size: 20px;
  justify-content: center;
}

.action-button {
  width: 12%;
  height: 370px;
}

.action-button button {
  padding: 10px 10px 10px 10px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 5px;
  background-color: #5e72e4;
  color: white;
  cursor: pointer;
}

.edit_setting_icon {
  font-size: 25px;
}

.form_setting {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form_setting label {
  padding-left: -1rem;
  font-size: 15px;
}

.setting_input {
  height: 40px;
  border-radius: 5px;
  margin-top: -15px;
}

.action-button_setting {
  display: flex;
  gap: 10px;
  align-items: center;
}

.action-button_setting button {
  padding: 8px 8px 8px 8px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 5px;
  background-color: #5e72e4;
  color: white;
  cursor: pointer;
}

.attendance_div {
  width: 70%;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  margin-top: 2rem;
}

.My_attendance {
  display: flex;
  align-items: center;
  width: 40%;
  margin: 1.5rem;
  position: relative;
  left: 9.5rem;
  cursor: pointer;
}

.My_attendance span {
  font-size: 25px;
}

.profile-image-div img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.guardLogoImage h1 {
  font-size: 50px;
}

.add-image-label {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: #5e72e4;
  color: white;
  justify-content: center;

  z-index: 1000;
  cursor: pointer;
}

.add-image-icon {
  font-size: 25px;
  cursor: pointer;
}

.profile_image_main {
  position: relative;

  top: -200px;
  left: 50px;
  cursor: pointer;
}
.user_inner_content {
  width: 150px !important;
}
.data-table-attendance {
  width: 60% !important;
}
.guard-acces-main-div {
  width: 80%;
  margin: auto;
}
