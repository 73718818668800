.heading_user {
    border-bottom: .5px solid rgb(255, 252, 252)
}
.heading_user {
    width: 100%;
    text-align: center;
    padding: 1rem;
    display: flex;
}
.all_data {
    color: #E11D48;
    font-size: 13px;
    text-transform: capitalize;
    text-decoration: underline #E11D48;
}
.close_btn {
    border: 1px solid rgb(201, 193, 193) !important;
    font-size: 1.7rem !important;
    width: 10% !important;
}

.close_icons {
    color: #E11D48;
}
.user_detail {

    width: 80%;
}
.main_content_div {
    display: flex;
    width: 100%;

}
.house_list_view{font-size: 17px !important;margin-left: -4rem;}
.no-data{width: 100% !important;;text-align: center;}
