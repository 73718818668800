.view-complaint-main-div {
  height: 300px;
  background-color: white;
}
.view-complaint-main-div {
  padding: 10px 0px;
  border-radius: 10px;
}
.view-announcement-content-card td {
  padding: 15px;
  font-size: 14px;
}
.edit-btn-complaint {
  /* height: 30px; */
  width: max-content;
  border-radius: 5px;
  color: #5e72e4;
  background-color: transparent;
  font-size: 25px;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.top-heading-compaints-div {
  height: auto;
  width: 70%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  font-size: 15px !important;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  justify-content: space-between;
}
.top-heading-compaints-div button {
  height: 48px;
  width: 150px;
  display: flex;
  align-items: center;
  display: flex;
  /* position: relative; */
  justify-content: center;
  padding: 5px 8px;
  background-color: #5e72e4;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
}
.top-heading-compaints-div-icons {
  font-size: 24px;
  width: 40px;
}

.top-heading-compaints-div button:disabled {
  /* cursor: not-allowed; */
  opacity: 0.2;
}

.top-heading-compaints-div .active-button {
  background-color:#5e72e4;
  color: white;
  border: 1px solid #5e72e4;
}
.active-button-disable{ opacity: 0.6;}