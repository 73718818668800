.main_search_And_add_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.admin_notfound_error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.no_data {
  width: 100%;
  position: relative;
  left: 50vh;
  text-align: center;
  padding: 1rem;
}
.user_import_search {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-top-div {
  display: flex;
  align-items: center;
  width: 100%;
}
.form_user {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-input-status {
  padding: 5px 5px 5px 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 1rem;
  width: 350px;
}
