body{ font-family: "Montserrat", sans-serif !important;}
.nav {
  height: max-content;
  width: 100%;
  background-color: #e2e5f8;
  display: flex;
  align-items: center;
  padding: 35px;
  color: #e11d48;
  justify-content: space-between;
  position: relative;
}

.logo span {
  color: #000;
}

.logo {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.profile {
  position: absolute;
  right: 210px;
}
.lang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 18%;
}

.switch {
  position: absolute;
  right: 25px;
}
.switch > span {
  position: absolute;
  top: 14px;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;

  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 30%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #5e72e4;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.check-toggle {
  position: absolute;

  visibility: hidden;
}

.check-toggle + label {
  display: block;
  position: relative;

  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 110px;

  /* background-color: #5e72e4; */
  -webkit-border-radius: 60px;
  height: 45px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  /* width: 50px; */
  /* background-color:#5e72e4; */

  border-radius: 60px;
}

input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 40px;

  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 73px;
}
.lang-cng:hover {
  cursor: pointer;
}
.switch :hover {
  cursor: pointer;
}

.heading_navbar {
  display: flex !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.add_entry_logo_navbar img {
  width: 100%;
  height: 100%;
}

.admin_specific_content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
  background-color: white;
  font-size: 27px;
}

.admin_specific_content_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_society_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: white;
}

.add_society_logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.add_society_logo_profile_Setting {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.show_inner_society_logo {
  position: absolute;
  left: 20px;
  top: 18px;
  cursor: pointer;
}
.affiliate-translation-heading {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif !important;

}
.corporate-translation-heading{display: flex;
  width: 77%;
  align-items: center;
  justify-content:end;
  font-family: "Montserrat", sans-serif !important;}
