.plan-basic {
    background-color: #E0F7FA; /* Light cyan color */
    color: #00796B; /* Darker cyan text color */
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  .plan-premium {
    background-color: #FFF3E0; /* Light orange color */
    color: #F57C00; /* Dark orange text color */
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  .plan-advanced {
    background-color: #F3E5F5; /* Light purple color */
    color: #8E24AA; /* Dark purple text color */
    padding: 5px 10px;
    border-radius: 3px;
  }
  .currency-inr {
    background-color: #E8F5E9; /* Light green for INR */
    color: #388E3C; /* Dark green text for INR */
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
  }
  
  .currency-usd {
    background-color: #E1F5FE; /* Light blue for USD */
    color: #0288D1; /* Dark blue text for USD */
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
  }
  .interval-monthly {
    background-color: #FFF9C4; /* Light yellow background for Monthly */
    color: #FBC02D; /* Golden yellow text */
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
  }
  
  .interval-annual {
    background-color: #E8F5E9; /* Light green background for Annual */
    color: #388E3C; /* Green text */
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
  }
  .interval-quarterly {
    background-color: #E1F5FE; /* Light blue background for Quarterly */
    color: #0288D1; /* Blue text */
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: bold;
  }