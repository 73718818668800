.card-header:first-child {
  border-radius: 7px !important;
}

.disabled-btn {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none;

    
  }
  