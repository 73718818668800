.main-admin {
  height: 550px;
  width: 100%;
  display: flex;
  overflow: hidden;

}

.admin-nav {
  height: 100%;
  width: 20% !important;
  background-color: #ece3e3;
  overflow-y: scroll;
}

.admin-nav ul {
  height: max-content;
  width: max-content;
  display: flex;
  flex-direction: column;

  /* gap: 2px; */
  font-size: 16px;
  margin-left: 7px;
}

.admin-nav ul a {
  background-color: white;
  text-decoration: none;
  font-weight: 500;
  color: black;
  margin: 2px;
  width: auto;
}

.admin-content {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.admin-content::-webkit-scrollbar {
  display: none;
}

.active {
  color: #5e72e4 !important;
  background-color: #e1e3f0 !important;
}

.request {
  font-size: 30px;
  color: black !important;
  border-bottom: 2px solid #e11d48;
  border-radius: 5px;
}

.actions {
  width: 200px !important;
}

.add {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 68px;
  /* position: relative; */
  border: 1px solid black;
}

.head {
  margin: 20px auto;
  text-align: center;
  font-size: 20px;
}

.head h2 {
  letter-spacing: 1px;
}

.data-table-house {
  width: 90%;
  margin: 20px auto;
}

.house-no {
  width: 140px !important;
}

.add-btnn {
  position: absolute;
  background-color: transparent;
}

.data_not h1 {
  font-size: 20px;
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}

.nav_spinner {
  width: 40%;

  /* display: flex;justify-content: center;align-items: center; */
}

.loader_width {
  width: 10%;
}

.edit-btn_add:hover {
  cursor: pointer;
}

.btn-2:hover {
  cursor: pointer;
}

.add_btn_purpose:hover {
  cursor: pointer;
}

@media screen and (orientation: landscape) and (min-device-width: 368px) and (max-device-width: 1370px) {
  .add-btn {
    right: 10px;
    top: 10px;
  }

  .table-maid {
    width: 80% !important;
    margin: 0 auto;
  }

  .data-table-entry {
    width: 80% !important;
    margin: 0 auto;
  }
}

.add_entry_logo {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  margin-left: 6rem;
}

.add_entry_logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit_Entry_image_modal {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-self: center;
}

.css-1wnsr1i {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_Entry_image_modal img {
  width: 100%;
  height: 100%;
}

.verify_entry {
  height: max-content;
}

.regular_entry_div {
  width: 100% !important;
  margin-left: -1rem;
}
.regular_ul {
  margin-top: -1rem;
  margin-left: 1.6rem;
}

.regular_main {
  margin-bottom: 0.2rem;
  /* margin-left: -0.4rem; */
}

.regular_entry_div li {
  list-style: none;
  cursor: pointer;
  font-size: 10px;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: none;
}

.active_item {
  color: #5e72e4;
}

.modal_edit_entry_image {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999 !important;
  /* m */
}

.css-1wnsr1i {
  position: absolute !important;
  top: 50%;
  left: 167% !important;
}

.add_entry_logo_not {
  background-color: #5e72e4;
  color: white;
}

.logo-regular-section {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.logo-regular-section h6 {
  color: white;
  font-size: 10px;
  padding-top: 10px;
}
.img-regular-section {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.regular_entries_title {
  width: 100%;
  margin-left: -1rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
}

.dropDown_regular_title {
  overflow-y: scroll;
  height: 20vh;
  margin-top: 0.5rem;
  transition: all 1s ease;
}

.dropDown_regular_title::-webkit-scrollbar {
  width: 7px;
}

.dropDown_regular_title::-webkit-scrollbar-thumb {
  background-color: #5e72e4;
  border-radius: 10px;
}

.dropDown_regular_title::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.admin_navbar_heading {
  font-weight: 500 !important;
}

.dropdown-logout-btn {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
}

.permission {
  display: flex !important;
  align-items: center !important;
  width: 100%;
  justify-content: center !important;
  height: 500px;
}

.entry_main_table {
  width: 94%;
}

.setting_list {
  width: 100%;
  margin-left: -1rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
}

.icon_setting {
  font-size: 20px;
}

.noneBackground {
  width: 100% !important;
  margin: 10px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: none !important;
}

.accordian_style_setting {
  padding: 1px 0px 0px 0px !important;
  margin: 1px 0px 0px 0px !important;
}

.three_circle_loader {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.data-table-entry {
  margin-left: auto;
}
.no_data_entry {
  padding: 0.5rem;
  font-size: 17px;
}
/* Sidebar styles */
.sidenav {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #f8f9fa;
  border-right: 1px solid #dee2e6;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.sidenav-visible {
  transform: translateX(0);
  opacity: 1;
}

.sidenav-hidden {
  transform: translateX(-100%);
  opacity: 0;
}

/* Sidebar toggle button */
.sidenav-toggler-inner {
  cursor: pointer;
}

.sidenav-toggler-line {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 5px 0;
}
.society_logo_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.society_logo_div img {
  border-radius: 50%;
  object-fit: cover;

}
.nav_spinner_navbarleft {
  /* border: 1px solid black; */
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  top: 40%;
}
.purpose_default_icon {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #5e72e4;
}
.purpose-default-main-div {

  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: 100%;
}
.purpose_default_icon h5 {
  font-size: 90px;
  color: white;
}
.regular_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.icon_font_size {
  font-size: 18px;
  /* margin-left: -.6rem; */
  font-weight: bold !important;
}
.society_logo_div h6 {
  padding-top: 18px;

  height: 60px;
  width: 70px;
}
.inner_society_logo_div {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inner_text_logo {
  width: 100%;
}

.guard_logo_div img {
  width: 100%;
  height: 100%;
}
.guard_logo_div {

display: flex;align-items: center;
width: 75%;height: auto;justify-content: center;
}

.nav-item {
  /* padding-left: 1rem !important; */
  text-align: center;
}
.side-nav-main-div {
  width: 100%;
  overflow: auto;
  height: 500px;
}

.side-nav-main-div ul {
  padding: 0;
  margin-bottom: 1.5rem;
}

.side-nav-main-div ul li {
  margin: 0;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.side-nav-main-div::-webkit-scrollbar {
  width: 2px;
}

.side-nav-main-div::-webkit-scrollbar-thumb {
  background: #5e72e4;
  border-radius: 4px;
}

.side-nav-main-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.css-9l5vo-MuiCollapse-wrapperInner {
  height: fit-content !important;
}
.css-9l5vo-MuiCollapse-wrapperInner {
  margin: 0 !important;
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  height: 20px !important;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 42px !important;
}
.society-logo-on-nav {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.edit-society-input {
  /* width: 44%; */
}
.eye-button-society {
  position: absolute;
  right: 40px;
}
.society-logo-img {
  height: 400px;
  overflow: hidden;
}
.society-logo-img img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}
.society_main_div select {
  margin: 1rem;
}


.plan-basic-society {
  /* background-color: #E0F7FA; */
  color: #00796B; /* Darker cyan text color */
  padding: 5px 10px;
  border-radius: 3px;
}

.plan-premium-society {
  /* background-color: #FFF3E0;  */
  color: #F57C00; /* Dark orange text color */
  padding: 5px 10px;
  border-radius: 3px;
}

.plan-advanced-society {
  /* background-color: #F3E5F5; */
  color: #8E24AA; /* Dark purple text color */
  padding: 5px 10px;
  border-radius: 3px;
}  .interval-monthly-society {

  color: #FBC02D; /* Golden yellow text */
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
}

.interval-annual-society {

  color: #388E3C; /* Green text */
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
}
.interval-quarterly-society {

  color: #0288D1; /* Blue text */
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
}
.society_bottom_btn_div button:disabled {
  background-color: #ccc; 
  color: #666;
  border: 1px solid #aaa; 
  cursor: not-allowed;
  opacity: 0.7; 
}
/* .society-email{border: 1px solid;} */


.input-wrapper-society-logo {
  position: relative;
  display: flex;
  align-items: center; /* Vertically center the input and icon */
  justify-content: space-between; /* Spread them out */
}

.edit-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.eye-button-society {
  position: absolute;
  right: 36px; /* Position the icon on the right */
  top: 50%;
  transform: translateY(-50%); /* Center the icon vertically */
  cursor: pointer;
}

.eyes_view {
  font-size: 24px;

}

@media (max-width: 768px) {
  .eye-button-society {
    right: 5px;
  }

  .edit-input {
    padding: 8px; /* Adjust padding for smaller screens */
  }
}
.error_logo{
  padding-left: 1rem;
  margin-top: -14px;
  font-size: 10px}
  

  .font-style {
    font-size: 12px; /* Adjust as needed */
    font-weight: bold;
  }