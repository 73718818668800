.calendar_main {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.calendar_header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.calendar_header li {
  list-style: none;
  cursor: pointer;
}
.calendar_content {
  display: flex;
  flex-wrap: nowrap; /* Prevent items from wrapping */
  gap: 20px;
  padding: 20px;
  justify-content: center;
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%;

}

.calendar_card {
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  transition: transform 0.3s ease;
}

.calendar_card:hover {
  transform: translateY(-10px);
}
.calendar {
  font-weight: bold;
  text-decoration: underline;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;

}
.dropdown_btn {
  width: 30% !important;
 
}
.calendar_heading {
  font-weight: 500;
  font-size: 16px;
  width: 30%;
  text-align: center;

  color: black;
}

.card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maid_name {
  font-size: 18px;
  font-weight: 600px;
  color: #333;

  font-size: 1.5rem;
  font-weight: 500;
  color: whitesmoke;
  text-align: start;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  background-color: #5e72e4;
  padding-left: 15px;
}
.clockInOut {
  margin-top: 10px;
  width: 100%;
}

.clockInOut div {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.clockInOut span {
  font-weight: bold;
}
li.activeTab {
  color: #5e72e4;
  font-weight: bold;
}

.calendar_carousel {
  display: flex;
  width: 100%;
  height: 46px;
  align-items: center;
}
.calender_clear_filter {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.day-display {
  color: #5e72e4;
  font-weight: 600;
  font-family: "poppins";
  cursor: pointer;
}
.clear-filter-btn {
  display: flex;
  align-items: center;

  flex-direction: column;

  height: 46px;
  justify-content: center;
  width: 65%;
  background-color: #5e72e4;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
}
.clear-filter-icon {
  font-size: 40px;
  font-weight: 500;
}
.prev-day-btn {
  background-color: none !important;
}
.next-day-btn {
  cursor: pointer;
}
.prev-day-btn {
  cursor: pointer;
}
.daily-attendance {
  position: relative;
}

.calendar-picker {
  position: fixed;
  top: 10;
  right: 0;
  margin: 10px;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.calendar-picker.closed {
  transform: translateX(100%);
}

.calendar-picker.open {
  transform: translateX(0);
}
.date {
  position: relative;
  /* border: 1px solid black; */
  width: 23%;
  display: flex;
  justify-content: center;
}
.calendar_content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;
}

.timeline_calender_content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow: auto;
}

.user-report {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background-color: #f9f9f9;
  position: relative;
  overflow: auto;
  width: 100%;
  height: 291px;
}

.time_entries {
  display: flex;
  flex-direction: column;
  background-color: #e5e8f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 8px;
  width: 100%;
}

.time_entry {
  border-bottom: 1px dashed black;
  padding: 0.5rem 0;
}

.time_entry_details {
  display: flex;
  align-items: center;
}

.label {
  font-weight: bold;
  margin-right: 0.5rem;
  width: 22%;
}

.down_icon {
  margin-right: 0.5rem;
}

.up_icon {
  margin-right: 0.5rem;
}

.user-report {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.time_entry {
  border-bottom: 1px dashed #ddd;
  padding: 0.5rem 0;
}

.time_entry_details {
  display: flex;
  align-items: center;
}

.time_entry_details {
  color: #555;
}
.calender_no_data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calender-search input {
  border: 1px solid rgb(225, 222, 222);
  height: 49px;
  border-radius: 5px !important;
  outline: none;
  width: 300px;
  font-family: inherit;
  padding-left: 15px;
  padding-right: 40px;
  margin-left: 0.5rem;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}
.export-wrapper {
  position: relative;
  width: 100%;
}
.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: grey;
  pointer-events: none;
}

/* Weekly Attendance */
.week-display {
  color: #5e72e4;
  text-decoration: underline;
  font-weight: 600;
  font-family: "poppins";
  cursor: pointer;
}
.calendar_carousel_weekly {
  display: flex;

  height: 46px;
  align-items: center;
}
.clear-filter-btn-weekly {
  display: flex;
  align-items: center;

  flex-direction: column;

  height: 39px;
  justify-content: center;
  width: 85%;
  background-color: #5e72e4;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
}
.prev-week-btn {
  cursor: pointer;
}
.next-week-btn {
  cursor: pointer;
}
/* Monthly Attendance */
.calendar_carousel_monthly {
  display: flex;
  width: 180px;
  height: 46px;
  align-items: center;
}
.clear-filter-btn-monthly {
  display: flex;
  align-items: center;

  flex-direction: column;

  height: 46px;
  justify-content: center;
  width: 45%;
  background-color: #5e72e4;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
}
.month-display {
  color: #5e72e4;
  text-decoration: underline;
  font-weight: 600;
  font-family: "poppins";
  cursor: pointer;
}
.prev-month-btn {
  cursor: pointer;
}
.next-month-btn {
  cursor: pointer;
}

/* Add responsive styling */
@media (max-width: 768px) {
  .calendar_content {
    flex-direction: column;
    align-items: center;
  }

  .calendar_card {
    width: 100%;
    max-width: 350px;
  }
  .calendar_main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.daily-report {
  padding:0px 20px 20px 20px;
  background-color: #f4f4f4;
  overflow-x: auto;
  height: 500px;
  width: 150%;

}

.report-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 13px !important;
  min-width: 800px;
}

.report-table th,
.report-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px dashed #ddd;
}

.report-table th {
  background-color: #5e72e4;
  color: white;
  font-weight: bold;
}

.report-table tr:hover {
  background-color: #f1f1f1;
}
.clear_filter_main {
  width: 70px;
}
.calender_clear_filter {
  width: 100%;
}
.thead_reports {
  position: sticky !important;
  top: -20px;
}

.daily-attendance-show-calender {
  position: absolute;
  z-index: 99;
  top: 45px;
  right: -60px;
}
.calender-close-button {
  font-size: 20px;
  cursor: pointer;
}

.dropdown-menu {
  transform: none !important;
  top: 10px !important;
  left: 50% !important;
  transform: translateX(-37%) !important;
  max-width: 90vw !important;
  width: fit-content !important;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px dashed black !important;
}
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
  height: 48px;
  border-radius: 5px !important;
}