.affiliate-contract-form {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    gap: 10px;
    height: fit-content !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    margin-top: 4rem;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: center;
    background-color: white;
    margin-top: 6rem;
    position: relative;
    flex-grow: 1;
  }
  .affiliate-form-div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content !important;
  }
  .affiliate-hourly-rate {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .edit-input-affiliate[type="date"] {
    position: relative;
    padding-right: 30px;
  }
  .edit-input-affiliate[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  