.images_view {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 300px;
  flex-wrap: wrap;
  overflow: auto;
  padding: 1rem;
}
.images_view::-webkit-scrollbar {
  width: 7px;
}

.images_view::-webkit-scrollbar-thumb {
  background-color: #5e72e4; 
  border-radius: 10px; 
}

.images_view::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.images_view img {
  width: 110px !important;
  margin: 5px;
  height: 110px !important;
  border: 1px solid #5e72e4;
  transition: transform 0.3s ease-in-out;
  cursor: pointer; 
}
.images_view img:hover {
  transform: scale(1.3); 

}
.cross_button {
  /* border: 1px solid black; */
  position: absolute;
  top: 5px;
  height: 30px;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin: 0.5rem;
  border-bottom: 1px dashed black;
}

.view_delete {
  display: flex;
  justify-content: flex-end;
}
.view_delete_cross {
  border: 1px solid rgb(194, 187, 187);
  border-radius: 50%;
  color: black;
  cursor: pointer;
}
.no-images-avaliable {
  display: flex;
  width: 100%;
  align-items: center;
  height: 200px;
  justify-content: center;

  text-align: center;
}
