.card-header-contract {
  border-radius: 7px !important;
}
.society-card {
  width: 250px;
  padding: 16px;
  border-radius: 8px;
  background-color: #f0f4fc;
  border: 1px solid #c3cde6;

  margin: 10px;
  font-family: "Montserrat", sans-serif;
}

.card-header-contract {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  flex-wrap: wrap;
  color: #888;
  margin-bottom: 10px;
}

.card-id {
  font-weight: bold;
}

.card-status {
  color: #e74c3c;
}

.card-content .card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.timeline {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 0.85rem;
  color: #555;
}

.timeline-date {
  width: 100px;
  text-align: center;
}

.timeline-line {
  flex: 1;
  height: 3px;
  margin: 0 5px;
  position: relative;
  border-radius: 3px;
}
.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 32px;
  height: 32px;
  background-color: #3498db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
}

.user-details .user-name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.user-details .user-source {
  font-size: 0.8rem;
  color: #777;
}

.action-button {
  background-color: #000;
  color: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status {
  margin-top: 8px;
  gap: 5px;
  display: flex;
  align-items: center;
}

.status-text {
  font-size: 10px;
  color: #666;
  font-weight: 500;
}
.affiliate-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
