.affiliate-side-nav-main-div ul li {
  margin: 5px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.affiliate-nav-link {
  font-weight: 600;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.5rem 1rem;
}
.affiliate-nav-link-text {
  display: flex;
  align-items: center;
}
.side-nav-top-logo {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  width: 75%;
}
.side-nav-top-logo img {
  width: 100%;
  height: 100%;
}
.nav-module-right{cursor: pointer !important;}