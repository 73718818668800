/* QRScanner.css */

.qr-scanner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
  }
  
  .qr-button {
    display: flex;
    align-items: center;
    /* background-color: #007bff; */
    color:   #5e72e4;;
    padding: 10px 20px;
    /* border: none; */
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .qr-button:hover {
    color: #007bff;
  }
  
  .qr-icon {
    margin-right: 8px;
  }
  
  .qr-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .qr-modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    position: relative;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  }
  
  .qr-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .qr-close-button {
    background: none;
    border: none;
    color: #ff4d4d;
    font-size: 16px;
    cursor: pointer;
  }
  
  .qr-scanner-box {
    width: 100%;
    height: 300px;
    background: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .qr-error-message {
    color: #ff4d4d;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .qr-gallery-upload {
    margin-top: 20px;
    text-align: center;
  }
  
  .qr-hidden-input {
    display: none;
  }
  
  .qr-upload-label {
    background: #ccc;
    color: #555;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .qr-upload-label:hover {
    background: #aaa;
  }
  
  .qr-result {
    background: #e0ffe0;
    color: #006400;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    width: 90%;
    text-align: center;
  }
  
  .qr-result-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .qr-modal-header h2{font-size: 20px; font-family: "Montserrat", sans-serif;}
  .sticky-buttons-container {
    position: sticky;
    top: 0; /* Sticks the buttons to the top when scrolling */
    z-index: 10; /* Ensures the buttons stay above other content */
    background: white; /* Ensures a clean background while scrolling */
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    border-bottom: 1px solid #ddd; /* Optional: adds a subtle divider */
  }
  
  .custom-approve-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .custom-approve-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 128, 0, 0.2);
  }
  
  .custom-reject-btn {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .custom-reject-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.2);
  }
  .my-swal-modal{
    z-index: 9999999;
  }