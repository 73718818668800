.forgot-submit-button {
    background-color: #000000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 100%;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 10px 0;
  }

  .forgot-submit-button:disabled {
    background-color: #d3d3d3; /* Light gray background */
    color: #a9a9a9; /* Gray text */
    cursor: not-allowed; /* Change cursor to not-allowed */
  }