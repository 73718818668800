.subscription-main-div {
  width: 90%;
  display: flex;
  margin-top: 5rem !important;
  border: 1px solid rgb(231, 223, 223);
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  background-color: white;
}
.subscription-form {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem;
  gap: 10px;
}
.subscription-submit-btn {
    display: flex;
    justify-content: center;
}
.subscription-submit-btn button {
    font-size: 1.2rem;
}

.subscription-submit-btn button{    background-color: #5e72e4;
    cursor: pointer;
    font-size: 22px;
    margin-top: 20px;
    width: 50%;
    width: 40%;
    border: none;}