.affiliate-rememberMe-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px;
  
}
.affiliate-rememberMe-div label {
  color: whitesmoke;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding-top: 5px;
  font-size: 16px;
}
.main-form-affiliate {
  /* margin: 120px auto; */
  height: 300px !important;
}
.main-form-affiliate {
  margin: 80px auto;
}
.form-container-affiliate-login {
  background-color: #5e72e4;
  height: 400px;
  color: white;
  padding: 20px;
  width: 375px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
}

.form-container-affiliate-login h2 {
  padding: 10px;
  height: 50px;
  text-align: center;
}
.form-container-affiliate-login button {
  height: 45px;
}
.affiliate-login_title {
  color: white;
  font-size: 30px;
}
.affiliate-bottom-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.affiliate-register-div {
  color: whitesmoke;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding-top: 5px;
  font-size: 16px;
}


.togglePasswordVisibilityLoginPageAffiliate {
  position: relative;
  top: 0px;
}
.togglePasswordVisibilityLoginPageAffiliate {
  width: 5%;
  position: absolute;
  cursor: pointer;
  top: -117px;
  right: 0px;
}