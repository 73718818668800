.society_table {
  width: 90%;
  margin: auto;
  margin-top: 3rem;
}

.add_btn_society {
  color: white;
}

.society_main_div {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* align-items: center; */
  padding-bottom: 1rem;
  margin-top: 4rem;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: center;
  background-color: white;
  margin-top: 6rem;
  position: relative;
}
.eye-button-society-logo {
  position: absolute; 
  right: 95px; 
  top: 13%;
  transform: translateY(-50%);
  cursor: pointer; 
  z-index: 1; 
}
@media (max-width: 600px) {
  .edit-input {
      padding: 8px; 
  }
  
  .eye-button-society-logo {
      right: 5px; 
  }
}
.society_main_div input {
  margin: 1rem;

}

.css-1wnsr1i {
  width: 61% !important;
  border: none !important;
}

.edit_society_lable {
  font-size: 22px;
  font-weight: 520;
  width: 100% !important;
  margin-left: 1rem;
}
.scoiety_deatil_heading {
  font-size: 24px;
}
.close_society_btn button {
  border: 1px solid black !important;
}
.error_msg_society {
  color: red;
  padding-left: 1rem;
  padding-top: -2rem;
  padding-bottom: 0.5rem;
  font-size: 13px;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  width: 50px !important;
  height: 55px !important;
  color: black !important;
}

.society_form_div {
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.society_btn {
  height: 40px;
  width: 200px !important;
  background-color: #5e72e4;
  color: white;
  padding: 5px;
  font-size: 20px;

  border-radius: 5px;
}

.society_bottom_btn_div {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 1rem;
  cursor: pointer;
}
.society_bottom_btn_div button {
  cursor: pointer;
  margin-bottom: 1rem;
}

.edit-input_gender {
  height: 40px;
  width: 350px !important;
  border: 1px solid rgba(238, 180, 190, 0.713);
  padding: 5px;
  font-size: 20px;
  border-radius: 5px;
  margin-left: 1rem;
  margin-top: 1rem;
}

.add_society_back_button {
  position: absolute;
  top: 10%;
  left: 1%;
}

.add_society_back_button button {
  border: none;
  height: 40px;
  width: 40px;
  background-color: white;
  color: #5e72e4;
  border-radius: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_society_back_button button:hover {
  cursor: pointer;
}

.society_top_heading {
  margin: 1rem;
}
.export_society_btn {
  padding: 5px;
  background-color: #e11d48;
  border-radius: 5px;
  color: white;
  /* display: flex; */
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
.p-export-btn-society button {
  height: 100%;
  width: 100%;
  background-color: #5e72e4;
  color: #ffffff;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  z-index: 1000;
}
.p-export-btn-society {
  height: 30px;
  width: 100px;
  background-color: #5e72e4;
  color: #ffffff;
  border-radius: 10px;
}
.society_btn_div {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
}

.search-input_society {
  width: 120%;
}

.three_circle_loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.society_add_btn {
  width: 95px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.society_add_btn button {
  width: 95px;
  height: 48px;
  border-radius: 5px;
  background-color: #5e72e4;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
}

.data-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.society_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.society_detail_heading {
  width: 100%;
  position: relative;
  top: -40px;
  background-color: #8090f8;
  color: white;
  height: 50px;
  font-size: 20px;

  padding: 10px 0 0 10px;
  font-weight: 800;
  border-top-left-radius: 8px;border-top-right-radius: 8px;

  font-family: "Montserrat", sans-serif;
  
}
.user_images_heading_society {
  font-size: 17px;
  padding-left: 1.5rem;
}
.eyes_view {
  font-size: 25px;
}
.edit_society_images {
  width: 48%;
}
.view-regular-images {
  color: #5e72e4;
  padding-left: 1rem;
}
.cross_regular_images {
  cursor: pointer;
}
.right_regular_image_div {
  cursor: pointer;
}

.eyes_view {
  cursor: pointer;
}
.show-top {
  z-index: 99999 !important;
}
.society-image-content {
  width: 350px;

  margin: 1rem;
}
.edit-society-label-for-view-section {
  font-size: 22px;
  font-weight: 520;
  width: 100% !important;
  padding-left: -1.5rem;
}
.select-multiple-images-text {
  font-size: 10px;
}
.add-society-confirm-password-div {
  width: 85%;
}
.edit-btn:disabled{opacity: .5;cursor: not-allowed;}
.edit-btn .eyes_view:disabled{cursor: not-allowed;} 
.view-society-logo-div{display: flex;align-items: center;justify-content: center;}
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.status-badge.approved {
  color: green;
  border: 2px solid green;
  background-color: #e6f8e6;
font-size: 10px ;
}
.status-badge.resolved {
  color: green;
  border: 2px solid green;
  background-color: #e6f8e6;
font-size: 10px ;
}

.status-badge.rejected {
  color: red;
  border: 2px solid red;
  background-color: #fdecec;
  font-size: 10px ;
}

.status-badge.pending {
  color: orange;
  border: 2px solid orange;
  background-color: #fff6e6;
  font-size: 10px ;
}
