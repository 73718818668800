.component-display {
  /* border: 1px solid black; */
  overflow: auto;
}
.component-display::-webkit-scrollbar {
  display: none;
}
.button-container-personal-section {
  display: flex;

  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  transition: background-color 0.3s ease; /* Add smooth transition */
}

.tab-button:focus {
  outline: none;
}

/* Active button styles */
.tab-button.activePersoanl {
  background-color: #f6f9fc; 
  color:  #5e72e4;
  border-radius: 5px;
  font-weight: 600;
}


@keyframes slide-in {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}