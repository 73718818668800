.add-house-detail-section {
  width: 100%;

  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}
.add-house-detail-title-div {
  height: 50px;
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #5e72e4;
  color: white;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-left: 12px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.add-house-detail-inner-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgb(247, 247, 247);
  overflow-y: auto;
  height: 300px;
  gap: 10px;
  padding: 20px;
}
.add-house-detail-inner-section::-webkit-scrollbar {
  display: none;
}
.form-container-house2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  justify-content: center;
}
.form-container-house1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.input-container-house {
  margin-bottom: 20px;
}

.input-container-house label {
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
  display: block;
}
.input-with-icon-house {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  background-color: white;
}
.input-with-radio-house-icon {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  width: 455px;
  background-color: white;
}
.input-with-radio-house-icon input {
  border: none;
  outline: none;
  width: 190px;
  height: 25px;
  font-size: 1rem;
}
.input-with-radio-house-icon label {
  display: flex;
  align-items: center;

  width: 90px;
}
.input-with-icon-house input {
  border: none;
  outline: none;
  width: 400px;
  height: 33px;
  font-size: 1rem;
}

.input-icon {
  margin-left: 10px;
  color: #888;
}

.input-with-icon-house:hover {
  border-color: #007bff;
}
.input-with-icon-house select {
  border: none;
  outline: none;
  width: 400px;
  height: 33px;
  font-size: 1rem;

  background-color: white;
  border-radius: 5px;
}

.input-with-icon-house select:focus {
  border-color: #007bff;
  outline: none;
}
.add-society-house-form-action-buttons {
  display: flex;
  justify-content: center;
}
.add-society-house-save-button {
  padding: 10px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  margin: -1rem 0px 8px 0px;
  font-family: "Montserrat", sans-serif;
}
.add-society-house-save-button {
  background-color: #5e72e4;
  color: white;
}

.add-society-house-save-button:hover {
  background-color: #0056b3;
}
.add-society-house-form-action-buttons-icon {
  font-size: 1.3rem;
}
.css-1ab2xsx {
  width: 100% !important;
}
