.visitor-card-view {
  margin: 10px;
}
.visitor-card-heading button {
  background-color: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}
.visitor-card-heading h2 {
  font-size: 22px;
}
.visitor-card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
.visitor-card-main {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1); /* adds a soft shadow */
  margin: 10px;
  padding: 10px;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Info item styles */
.info-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.info-label {
  font-size: 0.875rem;
  color: #6b7280;
}

.info-value {
  font-weight: 500;
  color: #111827;
}

.section-title {
  font-size: 17px;
}
.visitor-clock-out-button {
  font-size: 15px;
  height: 35px !important;
  padding: 5px;
  background-color: black;
  color: white;
  font-weight: 600;
}
.visitor-clock-out-div {
  display: flex;
  align-items: center;
}
