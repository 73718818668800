.roles_main_div {
  width: 90%;
  display: flex;
  margin-top: 5rem !important;
  border: 1px solid rgb(231, 223, 223);
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 20px;
  background-color: white;
}

.role_add_back_btn {
  font-size: 10px;
  margin-left: -2rem;
  margin-top: -1rem;
}

.form_roles {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
}

.form_roles input {
  margin: 1rem;
}

.form_roles select {
  margin: 1rem;
}

.form_roles label {
  margin-bottom: 0px !important;
  margin-left: 1rem;
}

.form_roles textarea {
  margin: 1rem;
}

.edit-input-role {
  height: 40px;
  width: 400px !important;
  border: 1px solid #5e72e4;

  padding: 5px;
  font-size: 20px;
  border-radius: 5px;
}

.role_submit_btn {
  display: flex;
  justify-content: center;
}

.back_icon_addroles {
  font-size: 25px;
}
.role_submit_btn button {
  font-size: 1.2rem;
}

.add_regular_div textarea {
  height: 40px;
  width: 420px !important;
  border: 1px solid rgba(255, 81, 110, 0.713);
  font-size: 20px;
  border-radius: 5px;
}

.form_roles input::placeholder {
  font-size: 1rem;
}

.form_roles textarea::placeholder {
  font-size: 1rem;
}

.main_permission {
  width: 97%;
  display: flex;
  height: max-content;
  align-items: center;
  margin-left: 1rem;
}

.main_permission table {
  width: 100%;
}

.main_permission table {
  border: 1px solid #ddd;
  text-align: left;
  border-collapse: collapse;
}

.main_permission th,
td {
  padding: 30px 17px;
  border-collapse: collapse;
}

.mondule-th-name {
  width: 30%;
}

.module-action-div {
  /* border: 1px solid black; */
  height: max-content;
  display: flex;
}
.module-action-div input {
  cursor: pointer;
}
.module-action-div label {
  cursor: pointer;
}
.role-type-selection input {
  cursor: pointer;
}
.role-type-selection label {
  cursor: pointer;
}
.role_submit_btn {
  /* border: 1px solid black; */
  width: 100%;
}

.edit_btn_create_role {
  width: 50%;
}

.data_tabel_roles {
  margin: 2rem;
}

.add-item-button_role {
  /* border: 1px solid black; */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* .add-item-button_role */
.add-item-button_role button {
  height: 48px;
  width: 100px;
  background-color: #e11d48;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0.8rem 0.8rem 0.8rem 0;
  justify-content: space-evenly;
}

.roles_loader {
  height: 400px;
}

.add-item-button_role button {
  cursor: pointer;
}
.error-roles-title {
  font-size: 15px;
  padding-left: 17px;
  padding-top: -15px;
  color:black !important;

  margin-top: -8px;
}
.error-roles-des {
  font-size: 15px;
  padding-left: 17px;
  margin-top: -16px;
  color:black !important;
}
.no_data_entry {
  text-align: center !important;
}
.icon_admin_navbar {
  font-size: 18px;
}
.add-role-back-button {
  margin-left: -7px;
}
.td_permission {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px; /* Adjust padding for better spacing */
}

.td_permission input[type="checkbox"] {
  margin-right: 8px; /* Space between checkbox and label */
}

.td_permission label {
  font-size: 14px;
  margin-left: 4px;
  white-space: nowrap;
}
input[type="checkbox"].disabled-checkbox {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}
input[type="checkbox"].disabled-checkbox + label {
  color: #888;
  cursor: not-allowed;
}
.disabled-checkbox {
  cursor: not-allowed;
  opacity: 0.5;
}
