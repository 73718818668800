.affiliate-transactions-view h5 {
  color: white;
}
.affiliate-transactions-view {
  display: flex;
  align-items: center;
}
.affiliate-transactions-view-heading button {
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 50px;
  color: #5e72e4;
  display: flex;
  font-size: 20px;
  height: 40px;
  justify-content: center;
  width: 40px;
  margin: 10px;
}
/* Overlay */

/* For Pending */
.highlight-row-danger {
  background-color: #ffe6e6; /* Light red background */
  color: #990000; /* Dark red text */
  font-weight: bold; /* Bold text */
}

/* For Rejected */
.highlight-row-warning {
  background-color: #fff5e6; /* Light orange background */
  color: #cc6600; /* Dark orange text */
  font-weight: bold; /* Bold text */
}

/* For Resolved */
.highlight-row-resolved {
  background-color: #e6ffe6; /* Light green background */
  color: #006600; /* Dark green text */
  font-weight: bold; /* Bold text */
}
.view-affiliate-transactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.view-transactions-clear-btn {
  width: 95px;
  height: 48px;
  border-radius: 5px;
  background-color: #5e72e4;
  color: white;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
}
.no-dispute {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-container {
  max-height: 540px; 
  overflow-y: auto; 
  overflow-x: auto;
  position: relative; 
}
.filtered-div-transactions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transactions-export-date-range-filter {
  display: flex;
  width: 50%;
  justify-content: space-between;
  gap: 10px;
  
}

.action-to-updated-payment-status-transactions {
  cursor: pointer;
  color: #5e72e4;
  font-size: 27px;
}