.affiliate-register-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.progress-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.progress-step {
  display: flex;
  align-items: center;
  position: relative;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.activeLine .circle {
  background-color: #5e72e4;
  color: white;
}

.line {
  width: 460px;
  height: 4px;
  background-color: #ddd;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.progress-step.activeLine + .line {
  background-color: #4caf50;
}

.form-container-affiliate-register h3{
  color:white;
}

.form-container-affiliate-register input{
  background-color:white;
  color:black;
}
.form-container-affiliate-register {
  /* background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

  background-color: #5e72e4;

  color: white;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
}

.form-step {
  display: flex;
  flex-direction: column;
}

.form-container-affiliate-register label {
  color:white;
  font-size:16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-weight: bold;
  
}

.form-container-affiliate-register input[type="text"],
.form-container-affiliate-register input[type="number"],
.form-container-affiliate-register input[type="email"],
.form-container-affiliate-register input[type="password"],
.form-container-affiliate-register input[type="file"] {
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container-affiliate-register textarea {
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container-affiliate-register button {
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container-affiliate-register button[type="button"] {
  /* background-color: #007bff;
  background-color: #5e72e4;
  color: white; */
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  letter-spacing: 1px;

}

.form-container-affiliate-register button[type="button"]:hover {
  /* background-color: #0056b3; */
  
}

.form-container-affiliate-register button[type="submit"] {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  letter-spacing: 1px;
  
}

.form-container-affiliate-register button[type="submit"]:hover {
  /* background-color: #007bff; */
  /* background-color: #0056b3; */
  background-color: white;
  color: #5e72e4;
}

.password-container {
  position: relative; /* Create a positioning context for the eye icon */
  display: flex;
  align-items: center; /* Align the input and icon vertically */
  width: 100%; /* Ensure the container takes up full width */
}

.password-input {
  width: 100%; /* Make the input take full width of the container */
  padding-right: 40px; /* Add space on the right for the eye icon */
  padding-left: 10px; /* Optional: Add some padding on the left */
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box; /* Include padding in width/height calculations */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px; /* Optional: Rounded corners */
}

.eye-icon {
  position: absolute; /* Position the icon inside the container */
  right: 10px; /* Place it on the right side */
  top: 50%; /* Vertically center the icon */
  transform: translateY(-50%); /* Adjust for perfect vertical centering */
  font-size: 20px; /* Adjust the icon size */
  color: #666; /* Icon color */
  cursor: pointer; /* Add a pointer cursor for interactivity */
}
