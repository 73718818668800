.affilate-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.affiliate-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.commission-edit input{height: 48px;}