.card-billing-heading2 {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #5e72e4;
  color: white;
  display: flex;
  align-items: center;

  justify-content: space-between;
}
.top-heading-billing-dropdown-allFilter {
  display: flex;
  gap: 10px;
  /* justify-content:space-evenly; */
  align-items: center;
  flex-wrap: wrap;;
}
.top-heading-all-filter-button {
  height: 45px;
  width: 60px;
}
.top-heading-all-filter-button button {
  padding: 10px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #5e72e4;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
.top-heading-clear-all-filter-button {
  height: 45px;
  width: 120px;
}
.top-heading-clear-all-filter-button button {
  padding: 10px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #5e72e4;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
.action-to-updated-payment-status {
  cursor: pointer;
  color: #5e72e4;
  font-size: 27px;
}
.action-download-bill {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-to-updated-payment-status {
  position: relative; /* Ensure proper positioning for tooltip */
  z-index: 10; /* Raise icon above other elements if necessary */
  cursor: pointer; /* Ensure it looks clickable */
}
.disabledPaymentButton {
  color: gray;
  pointer-events: none;
  cursor: not-allowed;
  font-size: 28px;
  opacity: 0.5;
}

.action-to-updated-payment-status {
  color: #5e72e4; /* Your preferred color for active status */
  cursor: pointer;
}
/* month dropdown css */
.filterItemWrapper {
  position: relative;
  display: flex;
  border: 1px solid black;
  align-items: center;
  height: 45px;
  width: fit-content;
  justify-content: center;
  padding: 10px 10px;
  /* font-size: 1rem; */
  border: none;
  border-radius: 4px;
  cursor: pointer;

  gap: 5px;
  background-color: #5e72e4;

  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.filterItemButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
}

.filterItemButton img {
  transition: all 300ms ease-out;
}

.filterItemDropdown {
  position: absolute;
  top: 45px;
  /* left: 2px; */
  z-index: 10;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  max-height: 290px;
  overflow-y: scroll;
}

.filterItemDropdownArrow {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}

.filterItemList {
  padding: 0.5rem;
  margin: 0;
  list-style: none;
}

.filterItemListItem {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filterItemListItem:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.filterItemListItemSelected {
  background-color: lightgray;
  border-radius: 4px;
}

.filterItemWrapper.disabled {
  pointer-events: none;
  opacity: 0.5; /* Visually show that it's disabled */
  cursor: not-allowed;
}
/* Scrollbar Styling for Dropdown */
.filterItemDropdown::-webkit-scrollbar {
  width: 8px;
}

.filterItemDropdown::-webkit-scrollbar-thumb {
  background-color: #5e72e4;
  border-radius: 4px;
}

.filterItemDropdown::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.billing-no-data{display: flex;align-items: center;justify-content: center;}
.commission-input {
  width: 60px;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 2px solid  #5e72e4;;

}
.no-data-billing-td{width: 100%;display: flex;align-items: center;justify-content: center;}