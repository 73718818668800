
.billing-container::-webkit-scrollbar {
  height: 8px;
}

.billing-container::-webkit-scrollbar-thumb {
  background-color: #5e72e4; 
  border-radius: 4px; 
  cursor: pointer;
}

.billing-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
.billing-container {
  padding: 10px;
  width: 100%;
  overflow: auto;
}
/* .billing-container::-webkit-scrollbar {
  height: 8px;
}

.billing-container::-webkit-scrollbar-thumb {
  background-color: #5e72e4;
  border-radius: 4px;
  cursor: pointer;
}

.billing-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
} */
.billing-container {
  scrollbar-color: #5e72e4 #f1f1f1; /* Thumb color and track color */
  scrollbar-width: thin; /* Thin scrollbar */
}

.section-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.card-billing-wrapper {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* padding: 20px; */
  flex: 1;
  min-width: 280px;
  min-height: 200px;
}

.card-billing-heading {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #5e72e4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-billing-heading1 {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #5e72e4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-billing-heading-button button {
  font-size: 15px;
  border-radius: 5px;
  padding: 5px;
}
.card-billing-body {
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  display: flex;
  margin: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card-billing-progress-body {
  display: flex;
  margin: 15px;
  justify-content: space-between;
  flex-direction: column;
}
.card-billing-body-details {
  width: 30%;
}
.card-billing-body-details p {
  display: flex;
  justify-content: space-evenly;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
}
.card-billing-body-details p h6 {
  color: gray;
  font-weight: 500;
}
.card-billing-body-details p strong {
  color: black;
}
.card-billing-progress-details p {
  height: 13px;
}
.card-billing-progress-details p h6 {
  height: 13px;
  color: gray;
  font-weight: 500;
}
.progress-bar-container {
  background: #e9ecef;
  height: 30px;
  border-radius: 4px;
  margin: 8px 0;
  width: 100%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}

.progress-bar {
  background: #007bff;
  height: 50px;
  width: 100%;
  border-radius: 4px;
}

.billing-table {
  width: 100%;
  border-radius: 10px !important;
  border-collapse: collapse;
  overflow: auto;
}

.billing-table {
  border: 1px solid rgb(221, 218, 218);
}

.billing-table td {
  border: 1px solid #f4f4f4;
  padding: 25px 10px;
  text-align: center;
  white-space: nowrap;
  /* z-index: 1; */
}
/* .billing-table th {
  border: 1px solid  rgb(228, 225, 225) !important;
  padding: 25px 10px;
  text-align: center;
  white-space: nowrap;
}
.billing-table th {
  background-color: #f4f4f4;
  position: sticky;
  top: -20px;
  z-index: 1;
  border: 1px solid #f4f4f4;
} */
.billing-table th {
  position: sticky;
  top: 0; /* Sticks header to the top */
  background-color: #f4f4f4;
  /* z-index: 1000 ; */
  text-align: center;
  padding: 10px;
  white-space: nowrap;
  border: 1px solid rgb(228, 225, 225);
}
.billing-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100% !important;
}
.billing-container::-webkit-scrollbar {

  height: 3px; /* Thinner horizontal scrollbar */
  width: 3px;
}

.billing-container::-webkit-scrollbar-thumb {
  background-color: #5e72e4; /* Set color of the scrollbar */
  border-radius: 2px; /* Rounded corners for the scrollbar */
  cursor: pointer;
}

.billing-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the track color */
}
.no-data-billing-td {
  border: none !important;
}
.action-download-bill {
  display: flex;
  gap: 10px;
  z-index: 1;
}
.action-download-pdf {
  cursor: pointer;
  color: #5e72e4;
  font-size: 25px;
}
.action-download-excel {
  cursor: pointer;
  color: #5e72e4;
  font-size: 25px;
}
.action-download-csv {
  cursor: pointer;
  font-size: 25px;
  color: #5e72e4;
}
.billing-list-search-bar {
  width: 100%;
  max-width: 200px;
  font-family: "Montserrat", sans-serif;

  background-color: #5e72e4;
  color: white;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  outline: none;
  cursor: pointer;
}
.billing-list-search-bar::placeholder {
  color: white;
}
.p-export-btn-billing {
  height: 50px;
  width: 100px;
  background-color: #5e72e4;

  border-radius: 10px;
}
.export_btn_bill {
  border-radius: 5px;
  background-color: #5e72e4;
  color: white;
}
/* dropdwon css */
/* .custom-dropdown {
  margin: 20px; /* Adjust margin */
/* } */
.custom-dropdown-toggle {
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent;
  border: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown-icon {
  font-size: 37px;
}
.custom-dropdown-toggle {
  color: white;
  font-size: 30px;
  border-radius: 10px;
}

.custom-dropdown-menu {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-top: 1rem !important;
}
.custom-dropdown-toggle {
  border: 1px solid black;
  padding: 5px 5px !important;
}
.custom-dropdown-menu .dropdown-item {
  color: #333;
}

.custom-dropdown-menu .dropdown-item:hover {
  background-color: #eaeaea;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0 !important;

  /* border-top: 0.3em solid; */
  /* border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; */
}

.billing-table th, .billing-table td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

.parent-container {
  position: relative; /* Ensures the sticky header stays scoped */
  overflow: auto; /* Enables scrolling */
  max-height: 1000px; /* Limit height to enable scrolling if needed */
  z-index: 0
}
