.affiliate-user-container {
  width: 790px;
  margin: auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.progress-indicator-user {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 30px;
  margin-top: 20px;
}

.progress-step-user {
  display: flex;
  align-items: center;
  position: relative;
}

.circle-user {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: #ddd;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.activeLineUser .circle-user {
  background-color: white;
  color: black;
}

.line-user {
  width: 660px;
  height: 4px;
  background-color: #ddd;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.progress-step-user.activeLineUser + .line-user {
  background-color: #4caf50;
}

.form-container-affiliate-user {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-step-user {
  display: flex;
  flex-direction: column;
}

.form-container-affiliate-user label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-weight: bold;
}

.form-container-affiliate-user input[type="text"],
.form-container-affiliate-user input[type="number"],
.form-container-affiliate-user input[type="email"],
.form-container-affiliate-user input[type="password"],
.form-container-affiliate-user input[type="file"] {
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-container-affiliate-user textarea {
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-container-affiliate-user button {
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container-affiliate-user button[type="button"] {
  background-color: #5e72e4;
  color: white;
  margin-right: 10px;
}

.form-container-affiliate-user button[type="button"]:hover {
  background-color: #5e72e4;
}

.form-container-affiliate-user button[type="submit"] {
  background-color: #5e72e4;
  color: white;
}

.form-container-affiliate-user button[type="submit"]:hover {
  background-color: #5e72e4;
}
.input-wrapper-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.input-wrapper-user input {
  width: 100%;
}
.eye-button-edit-user {
    position: absolute;
    right: 10px;
    top: 18px;
}