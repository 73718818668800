.months {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  border: 1px solid rgb(243, 238, 238);
}
.months button {
  padding: 10px;
  border-radius: 5px;
}

.month_heading {
  width: 40%;
}
.month_heading {
  display: flex;
  align-items: center;
  width: 50%;
}
.entries_Show_ul {
  display: flex;
  gap: 20px;
  margin-top: 0.5rem;
  border: 1px solid rgb(252, 249, 249);
  border-radius: 10px;
  padding-bottom: 1rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  height: 420px;
  width: auto !important;
  overflow: auto;
}
.top_card_heading {
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  background-color: rgb(94, 114, 228);
  color: white;
  font-weight: 600;
}

.entries_Show ul li {
  list-style: none;
}
.entry_user_image {
  width: 100%;
  height: 100%;
}

.month_show {
  width: 50%;
  display: flex;
  gap: 8px;
  border-radius: 5px;
  flex-wrap: wrap;
}
.button-active {
  background-color: #5e72e4;
  color: white;
}
.pagination-controls {
  display: flex;
  justify-content: end;
  width: 100%;
}
.pagination-controls-button {
  padding-top: 1rem;
}

.pagination-controls-button {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination-controls-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.pagination-controls-middle {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.pagination-controls-middle.active {
  background-color: #007bff;
  color: white;
}
.view_attendance_button {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
  background-color: #5e72e4;
  color: white;
  font-weight: 500px;
}
.card_purpose_content {
  display: flex;
  align-items: center;
}
.details_card {
  font-size: 14px;
  opacity: 0.7;
}
.card_clock {
  display: flex;
}
.card_clock_in {
  width: 50%;
}
.card_clock_out {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.requrie_button {
  align-items: center;
  color: red;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.content_regular_data {
  display: flex;
  margin-left: 0.8rem;
  margin-top: 1rem;
  gap: 10px;
  width: 100%;
}
.regular_entries_div {
  display: flex;
  width: 1000px;
  overflow: auto;
  height: 420px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  border-radius: 10px;
}
.view_maid_attandance {
  cursor: pointer;
  margin-right: 10px;
}
.down_icon {
  color: green;
}
.up_icon {
  color: red;
}

.regular_month_list {
  display: flex;
  justify-content: space-between;

  align-items: center;
}
.regular_month_div {
  width: 30%;
}
.date_range_filter {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.search_filter_guard_entry {
  width: 30%;
  height: 45px;
  display: flex;
  justify-content: end;
}
.carousel_width {
  position: relative;
  width: 80%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 4px 3px rgba(182, 178, 178, 0.2);
}
.carousel .control-dots {
  display: none !important;
}
.carousel-status {
  display: none;
}

.carousel .slider-wrapper {
  box-shadow: 0 4px 8px rgba(223, 220, 220, 0.2);
}

.carousel .control-arrow {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel .control-arrow {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .control-arrow.control-prev {
  left: 10px;
}

.carousel .control-arrow.control-next {
  right: 10px;
}

.carousel .control-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-button {
  cursor: pointer;
  font-weight: normal;
  padding: 10px;
  text-align: center;
  background: none;
  border: none;
}

.carousel-button.button-active {
  color: blue;
}
.table_cross_icon {
  width: 100%;
  display: flex;
  justify-content: end;
  margin: 10px;
}
.table_cross {
  padding: 10px;
  border-radius: 5px;
  font-size: 17px;
  color: black;
}
.icon_table {
  font-size: 30px;
  cursor: pointer;
  position: relative;
  top: -20px;
  left: -30px;
  padding: 5px;
  border: 1px solid rgb(190, 183, 183);
  border-radius: 50%;
  font-size: 29px;
  color: black;
  font-weight: 500;
}
.pagination_first_tab {
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 8px;
}
.pagination_sec_tab {
  display: flex;
  justify-content: end;
  position: relative;
  bottom: -23px;
}
.search_filter_firstTab {
  border: 1px solid black;
  width: 60%;
}
.search_filter_firstTab {
  display: flex;
  justify-content: end;
  align-items: center;
}

.regular_month_div_tab_two {
  width: 30%;
}
.entry_user_occasional_image {
  width: 40%;
  height: 40%;
}
.entry_data_view_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 650px !important;
  padding: 10px;
}
.css-10ghrmp-MuiPaper-root-MuiAppBar-root {
  background-color: rgb(226, 229, 248) !important;
  color: black !important
  ;
}
.guardEntry_No_data {
  font-size: 25px;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content_regular_data li {
  list-style: none;
}

/* //Renew Testing */
.main_div {
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  flex-direction: column;
}
.top_clender_date_range_search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.main_card_div {
  border: 1px solid black;
}
.no_Data_for_Regular_Entries {
  font-weight: bold;
  font-size: 25px;
}
.carousel-item button {
  background-color: #007bff; /* Customize button color */
  color: #fff;
}

.carousel-item button.active {
  background-color: #0056b3; /* Active button color */
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 9 !important;
  filter: alpha(opacity = 40);
  position: absolute;
  z-index: 2;
  top: 14px !important ;
  background: none;
  border: 0;

  font-size: 32px;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 30px !important;
  width: 30px !important
  ;
  background-color: rgb(140, 151, 222) !important;
}
.control-next {
  font-size: 10px !important;
}
.no_data_in_modal {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}
.guard_regular_entries_loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
