.view-society-contract-back-button {
  border: none;
  height: 40px;
  width: 40px;
  background-color: white;
  color: #5e72e4;
  border-radius: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(226, 221, 221);
  cursor: pointer;
}
