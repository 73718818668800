/* Style the SweetAlert popup */
.custom-swal-popup {
    background-color: #f8f9fa; /* Light gray background */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Add padding */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow */
  }
  
  /* Style the title */
  .custom-swal-title {
    font-family: 'Arial', sans-serif; /* Custom font */
    font-size: 1.5rem; /* Larger title font size */
    color: #333; /* Darker text color */
    margin-bottom: 15px; /* Spacing below the title */
  }
  
  /* Style the confirm button */
  .custom-swal-button {
    background-color: #007bff; /* Blue background */
    color: #fff; /* White text */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 20px; /* Add padding */
    font-size: 1rem; /* Adjust font size */
    cursor: pointer; /* Pointer cursor */
  }
  
  .custom-swal-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  