.affiliate-commission-edit-view {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.affiliate-commssion-edit-button {
  font-size: 25px;
  color: #5e72e4;
  cursor: pointer !important;
}
.commission-edit-cancel-view {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.commission-edit-cancel-view button {
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 11px;
}
.commission-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


