/* Parent container to position input and button relative to it */
.input-container {
  position: relative;
  display: inline-block; /* Keeps the input inline */
}

/* Input field styles */
.edit-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box; /* Ensures padding doesn’t affect input size */
}

/* Eye Button styles */
.eye-button {
  position: absolute;
  top: 50%; /* Aligns vertically in the middle */
  right: 20px; /* Position it on the right inside the input */
  transform: translateY(-50%); /* Adjusts the button's vertical alignment */
  background: none; /* No background */
  border: none; /* Remove default button border */
  color: #000; /* Choose color for the icon */
  font-size: 18px; /* Adjust the size of the eye icon */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
}

/* Optional styling for the button hover state */
.eye-button:hover {
  color: #007bff; /* Highlight the icon on hover */
}
