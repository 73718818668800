.house-owner-image-view-icon {
  font-size: 20px;
}
.owner-images-modal-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.owner-images-modal-view img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.owner-view-image-cross-button {
  display: flex;
  align-items: center;
  justify-content: end;
}
.owner-view-image-cross-button-icon {
  font-size: 27px;
  cursor: pointer;
}
.owner-modal-view-not-image-main{  display: flex;
    justify-content: center;
    height: 100px;
    width: 100%;}
.owner-modal-view-not-image {
  display: flex;
  justify-content: center;
  height: 120px;
  width: 120px;
  background-color:#5e72e4 !important; ;align-items: center;color: white;
  border-radius: 50%;
  font-size: 30px;
}
