.view-society-document-main-div {
  width: 100%;
  height: auto;
}
.view-society-documents-image-content {
  width: 100%;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  gap: 15px;
  margin: 0.8rem;
  justify-content: center;

  position: relative;
  top: 0px;
  left: 0px;
}
.view-society-documents-image-content img {
  width: 500px;
  height: 400px;
  object-fit: contain;
  aspect-ratio: 2 / 3;
  position: relative;
  top: 0px;
  left: 0px;
  padding: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  border: 1px dashed black;
  margin: 0.5rem;
}
.handle-add-society-media-button button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.society-documents-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1rem;
  border: 1px dashed black;
  padding: 1rem;
  gap: 10px;
}
.delete-button-document {
  position: relative;
  top: -20px;
  right: 28px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 999 !important;
}
.view-society-documents-content {
  gap: 20px;
}

.society-media-image {
  width: 100%;
  height: 100%;
  border: 1px dashed black;
  padding: 10px;
  object-fit: contain;
}

.society-media-pdf {
  width: 100%;
  height: 500px;
  border: 1px dashed black;
  padding: 10px;

  object-fit: contain;
}

.document-download-link {
  color: #007bff;
  text-decoration: underline;
}

.delete-button-document {
  cursor: pointer;
  color: red;
  font-size: 18px;
  margin-top: 10px;
}
.media-item {
  height: auto;
  width: 100%;
  display: flex;
}
.dialog-top {
  z-index: 9999999 !important;
}
#document-dialog-title {
  display: flex;
}
.view-image-pdf-title {
  width: 30%;
  font-family: "Montserrat", sans-serif;
}
.view-button {
  cursor: pointer;
}
